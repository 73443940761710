export default {
    logout: 'Se déconnecter',
    dashboard: 'Dashboard',
    profile: 'Profil',
    users: 'Utilisateurs',
    admin: 'Admin',
    actions: 'Actions',
    teams: 'Équipes',
    judge: 'Juge',
    robotGame: 'Match de Robot',
    scoreboard: 'Tableau des résultats',
    setup: 'Setup',
    schedule: 'Planning',
    userSettings: 'Paramètres utilisateur',
    settings: 'Paramétrage',
    tournaments: 'Tournois',
    tables: 'Tables',
    rounds: 'Manches',
    refereeResults: 'Résultat du jeu',
    wizard: 'Assistant',
    remarks: 'Remarques',
    export: 'Exporter'
};