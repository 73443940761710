export default {
    title: 'Teams',
    number: 'Nummer',
    name: 'Naam',
    numberLong: 'Teamnummer',
    nameLong: 'Teamnaam',
    id: 'ID',
    affiliate: 'Organisatie',
    actions: 'Acties',
    titleModalAdd: 'Team toevoegen',
    creationSuccess: 'Team is succesvol aangemaakt!',
    adv: {
        grouppicture: 'Groepsfoto',
        basicinfo: 'Basis Informatie',
        moreinfo: 'Meer Informatie',
        weblinks: 'Weblinks',
        yell: 'Yell',
        robot_desc: 'Robot omschrijving',
        reseach_desc: 'Innovatie project beschrijving',
        website: 'Website',
        instagram: 'Instagram',
        facebook: 'Facebook',
        twitter: 'X',
        snapchat: 'Snapchat'
    },
    public: {
        warningPracticeScore: 'Warning: This score is a practice round, these scores won\'t count towards the final result',
        viewResult: 'View scoreform',
        yell: 'Our yell',
        aboutRobot: 'About the robot',
        aboutResearch: 'About our research',
        whatsThePlan: 'What is the plan for today?',
        dayschedule: 'The schedule for',
        matchResults: 'Match results',
        scoreHidden: 'Score hidden'
    }
};