import moduleMsg from './settings/modules';
export default {
    modules: moduleMsg,
    title: 'Settings',
    finalName: 'Final name',
    moduleTitle: 'Modules',
    moduleDesc: 'The application has various modules, you can manage them here',
    openRegistration: 'Everyone can register',
    resultsDefaultPublic: 'Publish results automatically',
    basicTitle: 'Basic settings',
    basicDesc: 'These settings are required for running a final',
    timerTitle: 'Timer',
    timerDesc: 'Settings related to the timer'
};