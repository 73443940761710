import { usePage } from "@inertiajs/vue3";

export default {
    install: (app) => {
        // inject a globally available $settings() method

        app.config.globalProperties.$userCan = (key) => {
            const page = usePage();
            const abilities = page.props?.abilities;

            if (!abilities) {
                return false;
            }

            if (key === "") {
                return false;
            }

            const index = abilities
                .map((i) => i["name"].toLowerCase())
                .indexOf(key.toLowerCase());

            return index !== -1;
        };
    },
};
