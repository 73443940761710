export default {
    M00_long_name: "EQUIPMENT INSPECTION",
    M00_1_desc: "All team equipment fits in one launch area and under 12 in. (305 mm):",
    M01_long_name: "3D CINEMA",
    M01_1_desc: "The 3D cinema's red beam is completely to the right of the black frame:",
    M02_long_name: "THEATER SCENE CHANGE",
    M02_1_desc: "Your theater's red flag is down and the active scene color is:",
    M02_2_desc: "If the other side is the same color, select it:",
    M03_long_name: "IMMERSIVE EXPERIENCE",
    M03_1_desc: "The three immersive experience screens are raised:",
    M04_long_name: "MASTERPIECE SM",
    M04_1_desc: "Your team's LEGO® art piece is at least partly in the museum target area:",
    M04_2_desc: "The art piece is completely supported by the pedestal:",
    M05_long_name: "AUGMENTED REALITY STATUE",
    M05_1_desc: "The augmented reality statue's orange lever is rotated completely to the right:",
    M06_long_name: "MUSIC CONCERT LIGHTS AND SOUNDS",
    M06_1_desc: "The lights' orange lever is rotated completely downwards:",
    M06_2_desc: "The speakers' orange lever is rotated completely to the left:",
    M07_long_name: "HOLOGRAM PERFORMER",
    M07_1_desc: "The hologram performer's orange push activator is completely past the black stage set line:",
    M08_long_name: "ROLLING CAMERA",
    M08_1_desc: "The rolling camera's white pointer is left of:",
    M09_long_name: "MOVIE SET",
    M09_1_desc: "The boat is touching the mat and is completely past the black scene line:",
    M09_2_desc: "The camera is touching the mat and is at least partly in the camera target area:",
    M10_long_name: "SOUND MIXER",
    M10_1_desc: "Number of sound mixer sliders that are raised:",
    M11_long_name: "LIGHT SHOW",
    M11_1_desc: "The light show's white pointer is within zone:",
    M12_long_name: "VIRTUAL REALITY ARTIST",
    M12_1_desc: "The chicken is intact and has moved from its starting position:",
    M12_2_desc: "The chicken is over or completely past the lavender dot:",
    M13_long_name: "CRAFT CREATOR",
    M13_1_desc: "The craft machine's orange and white lid is completely open:",
    M13_2_desc: "The craft machine's light pink latch is pointing straight down:",
    M14_long_name: "AUDIENCE DELIVERY",
    M14_1_desc: "Number of audience members completely in a target destination:",
    M14_2_desc: "Number of target destinations with at least one audience member completely in it:",
    M15_long_name: "EXPERT DELIVERY",
    M15_1_desc: "Number of experts at least partly in their target destinations:",

};
