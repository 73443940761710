import { usePage } from "@inertiajs/vue3";

export default {
    install: (app) => {
        // inject a globally available $settings() method
        app.config.globalProperties.$settings = {};

        app.config.globalProperties.$settings.general = (
            key,
            defaultReturn = false
        ) => {
            const page = usePage();
            const settings = page.props.settings.general;

            if (key === "") {
                return defaultReturn;
            }

            const index = settings
                .map((i) => i["key"].toLowerCase())
                .indexOf(key.toLowerCase());

            if (index === -1) {
                return defaultReturn;
            }

            return settings[index].value;
        };

        app.config.globalProperties.$settings.modules = (
            key,
            defaultReturn = false
        ) => {
            const page = usePage();
            const settings = page.props.settings.modules;

            if (key === "") {
                return defaultReturn;
            }

            const index = settings
                .map((i) => i["key"].toLowerCase())
                .indexOf(key.toLowerCase());

            if (index === -1) {
                return defaultReturn;
            }

            return settings[index].value;
        };
    },
};
