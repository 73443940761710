import moduleMsg from './settings_modules';
export default {
    modules: moduleMsg,
    title: 'Paramétrage',
    finalName: 'Nom final',
    moduleTitle: 'Modules',
    moduleDesc: 'L\'application a plusieurs modules, vous pouvez les gérer ici',
    openRegistration: 'Tout le monde peut s\'inscrire',
    resultsDefaultPublic: 'Publier les résultats automatiquement',
    basicTitle: 'Paramètres de base',
    basicDesc: 'Ces paramètres sont requis pour exécuter une finale',
    timerTitle: 'Minuterie',
    timerDesc: 'Paramètres liés à la minuterie'
};