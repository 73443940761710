export default {
    title: 'Score input',
    yes: 'Ja',
    no: 'Nee',
    blue: 'Blauw',
    pink: 'Roze',
    orange: 'Oranje',
    lightBlue: 'Licht blauw',
    darkBlue: 'Donkerblauw',
    yellow: 'Geel',
    green: 'Groen',
    gp_long_name: 'Precisietokens',
    gp_desc: 'Aantal resterende precisietokens:',
    description: 'Beschrijving',
    updates: 'Updates',
    noChange: 'No change'
};