import { createI18n } from "vue-i18n";

import msgEN from "../Lang/en/messages";
import msgNL from "../Lang/nl-NL/messages";
import msgNLBE from "../Lang/nl-BE/messages";
import msgENZA from "../Lang/en-ZA/messages";
import msgIT from "../Lang/it-IT/messages";
import msgFR from "../Lang/fr-FR/messages"

const messages = {
    en: msgEN,
    nl: msgNL,
    be: msgNLBE,
    enza: msgENZA,
    it: msgIT,
    fr: msgFR,
};

// eslint-disable-next-line new-cap
export default new createI18n({
    legacy: false, // Vuetify does not support the legacy mode of vue-i18n
    locale: localStorage.getItem("lang") || "en",
    fallbackLocale: "en",
    messages,
    // fallbackWarn: false,
    // missingWarn: false,
});
