export default {
    401: {
        titleHead: "401 - Unauthorized",
        title: "Eeeh...",
        subtitle: "Error 401 - Unauthorized",
        lineone: "You are currently not logged in. Please login and try again",
        linetwo: "",
        backbutton: "Press here to login",
        report: "If this error keeps presisting, please contact helpdesk[at]flltools.com"
    },
    403: {
        titleHead: "403 - No Access",
        title: "Eeeh...",
        subtitle: "Error 403 - No Access",
        lineone: "You don't have the correct permissions to visit this page.",
        linetwo: "",
        backbutton: "Back to the previous page",
        report: "Do you think you should have access to this page? Contact your organisation"
    },
    404: {
        titleHead: "404 - Page not found",
        title: "Eeeh...",
        subtitle: "Error 404 - Page not found",
        lineone: "Maybe you clicked an expired link, or old bookmark.",
        linetwo: "",
        backbutton: "Click here to go back",
        report: "If this error keeps presisting, please contact helpdesk[at]flltools.com"
    },
    500: {
        titleHead: "500 - Internal Server Error",
        title: "Eeeh...",
        subtitle: "Error 500 - Internal Server Error",
        lineone: "Something went wrong on the server",
        linetwo: "Please try again later, we will try to fix it",
        backbutton: "Go a page back",
        report: "If this error keeps presisting, please contact helpdesk[at]flltools.com"
    }

};
