export default {
    title: 'Planifier',
    typeOfSchedule: 'Type d\'élément de planification',
    startTime: 'Heure de début',
    endTime: 'Heure de fin',
    jury: 'Salle de délibération',
    team: 'Equipe',
    round: 'Manche',
    table: 'Table',
    type: 'Type',
    text: 'Texte',
    category: 'Catégorie',
    showEndTime: 'Afficher l\'heure de fin'
};