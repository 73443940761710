import menuMsg from './menu';
import profileMsg from './profile';
import teamsMsg from './teams';
import scoreboardMsg from './scoreboard';
import generalMsg from './general';
import settingsMsg from './settings';
import tournamentMsg from './tournaments';
import authMsg from './auth';
import vuetifyMsg from './vuetify';
import roundsMsg from './rounds';
import errorMsg from './error';
import tablesMsg from './tables';
import scoresheetMsg from "./scoresheet"

export default {
    menu: menuMsg,
    profile: profileMsg,
    teams: teamsMsg,
    scoreboard: scoreboardMsg,
    general: generalMsg,
    settings: settingsMsg,
    tournament: tournamentMsg,
    auth: authMsg,
    rounds: roundsMsg,
    error: errorMsg,
    tables: tablesMsg,
    scoresheet: scoresheetMsg,
    $vuetify: vuetifyMsg
};
