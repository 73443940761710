export default {
    itemsPerPageText: "Items per page:",
    itemsPerPageAll: "All",
    pageText: "{0}-{1} of {2}",
    loadingText: "Retrieving data...",
    nextPage: "Next page",
    lastPage: "Last page",
    prevPage: "Previous page",
    firstPage: "First page",
};
