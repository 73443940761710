export default {
    title: 'Remarques',
    madeBy: 'Fait par',
    category: 'Catégorie',
    remark: 'Remarques',
    aboutTeam: 'Commentaire sur :',
    value: 'La remarque',
    coach: 'Coach',
    jury: 'Jury',
    table: 'Table',
    team: 'Equipe',
    parents: 'Parents',
    others: 'Autres'
};