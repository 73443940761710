import * as Sentry from "@sentry/vue";
import { router } from "@inertiajs/vue3";

function inertiaRoutingInstrumentation(
    customStartTransaction,
    startTransactionOnPageLoad = true,
    startTransactionOnLocationChange = true
) {
    let activeTransaction;
    let name;
    if (startTransactionOnPageLoad) {
        name = route().current();
        activeTransaction = customStartTransaction({
            name,
            op: "pageload",
            metadata: {
                source: "route",
            },
        });
    }

    if (startTransactionOnLocationChange) {
        router.on("before", (_to, _from) => {
            if (activeTransaction) {
                activeTransaction.finish();
            }

            const newName = route().current();

            if (newName !== name) {
                activeTransaction = customStartTransaction({
                    name: newName,
                    op: "navigation",
                    metadata: {
                        source: "route",
                    },
                });
            }
        });

        router.on("finish", () => {
            activeTransaction.setName(route().current(), "route");
        });
    }
}

export default function initSentry(app) {
    Sentry.init({
        app,
        // TODO Hardcoded dsn
        dsn: "https://6d65a41b987c875427ee0accdd6913a3@sentry.fll.tools/3",
        tracesSampleRate: 0.1,
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: inertiaRoutingInstrumentation,
            }),
            new Sentry.Replay(),
        ],
    });
}
