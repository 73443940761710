export default {
    moduleTable: 'Tafels Module',
    moduleRound: 'Rondes Module',
    moduleJudge: 'Jury Module',
    moduleSeasons: 'Seizoens Module',
    moduleAdvTeams: 'Geavanceerd Team Management',
    moduleMoreExports: 'More Exports',
    moduleDisplay: 'Displaymodule (BETA)',
    moduleButtons: 'Knopmodule (BETA)',
    moduleSchedule: 'Schedule Module',
    moduleScheduleGen: 'Schematic Generator Module (Not implemented yet)',
    moduleLivestream: 'Livestream Module'
};