import moduleMsg from './settings_modules';
export default {
    modules: moduleMsg,
    title: 'Instellingen',
    finalName: 'Finale naam',
    moduleTitle: 'Modules',
    moduleDesc: 'De applicatie heeft verschillende modules, hier kunnen deze aan en uit worden gezet',
    openRegistration: 'Iedereen mag registreren',
    resultsDefaultPublic: 'Resultaten automatisch openbaar maken',
    basicTitle: 'Basis instellingen',
    basicDesc: 'Deze instellingen zijn benodigd voor het draaien van de finale',
    timerTitle: 'Timer',
    timerDesc: 'Instellingen gerelateerd aan de timer'
};