export default {
    title: 'Scoreboard',
    header: 'Scoreboard',
    place: 'Rank',
    round: 'Round',
    score: 'Score',
    admin: {
        header: 'Administrative Scoreboard',
    },
};
