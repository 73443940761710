export default {
    401: {
        titleHead: '401 non autorisé',
        title: 'Eeeh...',
        subtitle: '401 non autorisé',
        lineone: 'Vous n\'êtes pas connecté. Veuillez vous connecter et réessayer',
        linetwo: '',
        backbutton: 'Appuyez ici pour vous connecter',
        report: 'Si cette erreur persiste, veuillez contacter le helpdesk[at]flltools.com'
    },
    403: {
        titleHead: '403 - Pas d\'accès',
        title: 'Eeeh...',
        subtitle: 'Erreur 403 - Pas d\'accès',
        lineone: 'Vous n\'avez pas les droits suffisants pour visiter cette page.',
        linetwo: '',
        backbutton: 'Retour à la page précédente',
        report: 'Pensez-vous que vous devriez avoir accès à cette page? Contactez votre organisation'
    },
    404: {
        titleHead: '404 - Page introuvable',
        title: 'Eeeh...',
        subtitle: 'Erreur 404 : page introuvable',
        lineone: 'Peut-être que vous avez cliqué sur un lien expiré, ou sur un ancien signet.',
        linetwo: '',
        backbutton: 'Cliquez ici pour revenir en arrière',
        report: 'Si cette erreur persiste, veuillez contacter le helpdesk[at]flltools.com'
    },
    500: {
        titleHead: '500 - Erreur interne du serveur',
        title: 'Eeeh...',
        subtitle: 'Erreur 500 : Erreur interne du serveur',
        lineone: 'Quelque chose s\'est mal passé sur le serveur',
        linetwo: 'Veuillez réessayer plus tard, nous essaierons de le corriger',
        backbutton: 'Revenir à la page précédente',
        report: 'Si cette erreur persiste, veuillez contacter le helpdesk[at]flltools.com'
    }
};