export default {
    title: "General",
    add: "Add",
    close: "Close",
    save: "Save",
    edit: "Edit",
    show: "Show",
    delete: "Delete",
    actions: "Actions",
    active: "Active",
    search: "Search",
    update: "Update",
    language: "Language",
    upload: "Upload",
    score: "Score",
    created_at: "Created at",
    priority: "Priority",
    download: "Download",
    referee: "Referee",
    points: "points",
    table: "Table",
    judgeSession: "Jury Session",
    match: "Robot Match"
};
