export default {
    title: 'Algemeen',
    add: 'Toevoegen',
    close: 'Sluiten',
    save: 'Opslaan',
    edit: 'Bewerken',
    show: 'Bekijken',
    delete: 'Verwijderen',
    actions: 'Acties',
    active: 'Actief',
    search: 'Zoeken',
    update: 'Updaten',
    language: 'Taal',
    upload: 'Uploaden',
    score: 'Score',
    created_at: 'Aangemaakt op',
    priority: 'Prioriteit',
    download: 'Download',
    referee: 'Referee',
    points: 'points',
    table: 'Table'
};