export default {
    moduleTable: 'Table Module',
    moduleRound: 'Rounds Module',
    moduleJudge: 'Judge Module',
    moduleSeasons: 'Seasons Module',
    moduleAdvTeams: 'Advanced Team Management',
    moduleMoreExports: 'More Exports',
    moduleDisplay: 'Displaymodule (BETA)',
    moduleButtons: 'Buttonmodule (BETA)',
    moduleSchedule: 'Schedule Module',
    moduleScheduleGen: 'Schematic Generator Module (Not implemented yet)',
    moduleLivestream: 'Livestream Module'
};
