import "./bootstrap";
import "../css/app.css";
import initSentry from "./plugins/sentry.js";

import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from "../../vendor/tightenco/ziggy/dist/vue.m";

import vuetify from "./plugins/vuetify";
import settings from "./plugins/settings.js";
import abilities from "./plugins/abilities.js";
import { createPinia } from "pinia";

const appName =
    window.document.getElementsByTagName("title")[0]?.innerText || "Laravel";

const pinia = createPinia();

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        ),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });

        initSentry(app);

        return app
            .use(plugin)
            .use(ZiggyVue)
            .use(vuetify)
            .use(settings)
            .use(abilities)
            .use(pinia)
            .mount(el);
    },
    progress: {
        color: "#4B5563",
    },
});
