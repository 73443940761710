export default {
    title: 'Gebruikers',
    basicInfo: 'Basis informatie',
    roles: 'Rollen',
    assignedTeams: 'Aangewezen Teams',
    passwordReset: 'Wachtwoord Resetten',
    name: 'Naam',
    email: 'Email',
    locale: 'Taal',
    current_team_id: 'Teamlijst',
    password: 'Wachtwoord',
    passwordConfirm: 'Bevestig Wachtwoord',
    titleModalAdd: 'Maak nieuwe gebruiker'
};