export default {
    title: 'Utilisateurs',
    basicInfo: 'Informations de base',
    roles: 'Rôles',
    assignedTeams: 'Équipes assignées',
    passwordReset: 'Réinitialisation du mot de passe',
    name: 'Nom',
    email: 'E-mail',
    locale: 'Langue',
    current_team_id: 'Liste des équipes',
    password: 'Mot de passe',
    passwordConfirm: 'Confirmer le mot de passe',
    titleModalAdd: 'Créer un utilisateur'
};