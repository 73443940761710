export default {
    moduleTable: 'Tafel Module',
    moduleRound: 'Rondes Module',
    moduleJudge: 'Jury Module',
    moduleSeasons: 'Seizoen module',
    moduleAdvTeams: 'Geavanceerd Team Management',
    moduleMoreExports: 'Meer exports',
    moduleDisplay: 'Displaymodule (BETA)',
    moduleButtons: 'Knopmodule (BETA)',
    moduleSchedule: 'Schema module',
    moduleScheduleGen: 'Schema generator module (Not implemented yet)',
    moduleLivestream: 'Livestream module'
};