export default {
    title: 'Wedstrijd management',
    tournament: 'Wedstrijd',
    tournaments: 'Wedstrijden',
    name: 'Tournament naam',
    seasonName: 'Seizoensnaam',
    startTime: 'Begintijd',
    endTime: 'Eindtijd',
    lastRoundHidden: 'Verberg laatste robot ronde',
    practiceRound: 'Eerste ronde is een oefenronde',
    titleModalAdd: 'Nieuw tournament',
    locationName: 'Locatienaam',
    address: 'Adres',
    city: 'Plaats',
    zipCode: 'Postcode',
    matchLength: 'Lengte wedstrijd',
    juryLength: 'Lengte jurysessie',
    simultaneousMatches: 'Aantal gelijktijdige wedstrijden',
    creationSuccess: 'Tournament is succesvol aangemaakt.',
    scoreSheets: 'Selecteer een scoreformulier',
    titleModalEdit: 'Wijzig Tournament'
};