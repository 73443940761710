export default {
    title: "Score input",
    yes: "Yes",
    no: "No",
    blue: "Blue",
    pink: "Pink",
    orange: "Orange",
    lightBlue: "Light blue",
    darkBlue: "Dark blue",
    yellow: "Yellow",
    green: "Green",
    gp_long_name: "PRECISIONTOKENS",
    gp_desc: "If the number of precision tokens remaining is:",
    description: "Description",
    updates: "Updates",
    noChange: "No change",
};
