export default {
    title: 'Tournament management',
    tournament: 'Tournament',
    tournaments: 'Tournaments',
    name: 'Tournament name',
    seasonName: 'Seasonname',
    startTime: 'Starting time',
    endTime: 'End time',
    lastRoundHidden: 'Hide last robot round',
    practiceRound: 'First round is practice round',
    titleModalAdd: 'Create new tournament',
    locationName: 'Location name',
    address: 'Address',
    city: 'City',
    zipCode: 'Zipcode',
    matchLength: 'Match length duration',
    juryLength: 'Jury session duration',
    simultaneousMatches: 'Simultaneous matches',
    creationSuccess: 'Tournament has been created successfully.',
    scoreSheets: 'Select a scoresheet',
    titleModalEdit: 'Edit Tournament'
};