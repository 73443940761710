export default {
    logout: "Logout",
    dashboard: "Dashboard",
    profile: "Profile",
    users: "Users",
    admin: "Admin",
    actions: "Actions",
    teams: "Teams",
    judge: "Judge",
    robotGame: "Robot Match",
    scoreboard: "Scoreboard",
    setup: "Setup",
    schedule: "Schedule",
    userSettings: "User settings",
    settings: "Settings",
    tournaments: "Tournaments",
    tables: "Tables",
    rounds: "Rounds",
    refereeResults: "Game results",
    wizard: "Wizard",
    remarks: "Remarks",
    export: "Exports",
    jury: "Jury",
};
