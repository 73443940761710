export default {
    moduleTable: 'Module de table',
    moduleRound: 'Module Rounds',
    moduleJudge: 'Module de juge',
    moduleSeasons: 'Module de saisons',
    moduleAdvTeams: 'Gestion d\'équipe avancée',
    moduleMoreExports: 'Plus d\'exportations',
    moduleDisplay: 'Module d\'affichage (BETA)',
    moduleButtons: 'Module de bouton (BETA)',
    moduleSchedule: 'Planifier le module',
    moduleScheduleGen: 'Module générateur de schématique (non implémenté encore)',
    moduleLivestream: 'Module Live Ream'
};