export default {
    401: {
        titleHead: '403 - Unauthorized',
        title: 'Eeeh...',
        subtitle: 'Error 401 - Unauthorized',
        lineone: 'Je bent momenteel niet ingelogd, log in en probeer het opnieuw',
        linetwo: '',
        backbutton: 'Klik hier om in te loggen',
        report: 'Mocht deze error zich blijven voordoen, neem dan contact op met helpdesk[at]flltools.nl'
    },
    403: {
        titleHead: '403 - Geen toegang',
        title: 'Eeeh...',
        subtitle: 'Error 403 - Geen toegang',
        lineone: 'Je hebt niet de juiste rechten om deze pagina te bezoeken',
        linetwo: '',
        backbutton: 'Terug naar de vorige pagina',
        report: 'Zou je wel toegang moeten hebben, neem dan contact op met de organisatie.'
    },
    404: {
        titleHead: '404 - Pagina niet gevonden',
        title: 'Eeeh...',
        subtitle: 'Error 404 - Pagina niet gevonden',
        lineone: 'Misschien is de link verlopen, of klopt je bladwijzer niet meer.',
        linetwo: '',
        backbutton: 'Klik hier om in te loggen',
        report: 'Mocht deze error zich blijven voordoen en weet je zeker dat alles klopt? Neem dan contact op met helpdesk[at]flltools.nl'
    },
    500: {
        titleHead: '500 - Internal Server Error',
        title: 'Eeeh...',
        subtitle: 'Error 500 - Internal Server Error',
        lineone: 'Er zijn wat blokjes in de server toch niet helemaal goed geplaatst',
        linetwo: 'We gaan ze proberen weer op de juiste plaats te zetten!',
        backbutton: 'Ga terug naar de vorige pagina',
        report: 'Mocht deze error zich blijven voordoen, neem dan contact op met helpdesk[at]flltools.nl'
    }
};
