export default {
    title: 'Gestion des tournois',
    tournament: 'Tournoi',
    tournaments: 'Tournois',
    name: 'Nom du tournoi',
    seasonName: 'Nom de la saison',
    startTime: 'Heure de début',
    endTime: 'Heure de fin',
    lastRoundHidden: 'Cacher la dernière manche du robot',
    practiceRound: 'La première manche est la manche d\'entraînement',
    titleModalAdd: 'Créer un nouveau tournoi',
    locationName: 'Nom de l’emplacement',
    address: 'Adresses',
    city: 'Ville',
    zipCode: 'Code postal',
    matchLength: 'Durée du match',
    juryLength: 'Durée de la session du Jury',
    simultaneousMatches: 'Correspondances simultanées',
    creationSuccess: 'Le tournoi a été créé avec succès.',
    scoreSheets: 'Sélectionnez une feuille de score',
    titleModalEdit: 'Modifier le Tournoi'
};