export default {
    title: 'Schedule',
    typeOfSchedule: 'Type of Schedule Item',
    startTime: "Start time",
    endTime: "End time",
    jury: "Judge Lane",
    team: "Team",
    round: "Round",
    table: "Table",
    type: "Type",
    text: "Text",
    category: "Category",
    showEndTime: "Show end time",
};
