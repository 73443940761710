export default {
    title: 'Équipes',
    number: 'Numéros',
    name: 'Nom',
    numberLong: 'Numéro d’équipe',
    nameLong: 'Nom de l\'équipe',
    id: 'ID',
    affiliate: 'Organisation',
    actions: 'Actions',
    titleModalAdd: 'Créer une équipe',
    creationSuccess: 'L\'équipe a été créée avec succès !',
    adv: {
        grouppicture: 'Photo de groupe',
        basicinfo: 'Informations de base',
        moreinfo: 'Plus d\'informations',
        weblinks: 'Liens internet',
        yell: 'Poigne',
        robot_desc: 'Description du robot',
        reseach_desc: 'Description du projet d\'innovation',
        website: 'Site Web',
        instagram: 'Instagram',
        facebook: 'Facebook',
        twitter: 'X',
        snapchat: 'Snapchat'
    },
    public: {
        warningPracticeScore: 'Attention : Ce score est une manche d\'exercice, ces scores ne compteront pas dans le résultat final',
        viewResult: 'Voir la réforme de score',
        yell: 'Nos cris',
        aboutRobot: 'À propos du robot',
        aboutResearch: 'À propos de nos recherches',
        whatsThePlan: 'Quel est le plan pour aujourd\'hui?',
        dayschedule: 'Le calendrier pour',
        matchResults: 'Résultats du match',
        scoreHidden: 'Score masqué'
    }
};