export default {
    title: 'Rounds',
    name: 'Roundname',
    is_practice: 'Practice round',
    score_hidden: 'Hide scores',
    titleModalAdd: 'Create round',
    titleModalShow: 'View round',
    titleModalEdit: 'Edit round',
    game_round: 'Game Round',
    score_visible: 'Visible',
    type: 'Type',
    visibility: 'Score visability'
};