export default {
    save: 'Save',
    saved: 'Saved.',
    enable: 'Enable',
    title: 'Profile',
    cancel: 'Cancel',
    disable: 'Disable',
    logout: 'Logout',
    done: 'Done.',
    code: 'Code',
    close: 'Close',
    confirm: 'Confirm',
    profileInfoTitle: 'Profile Information',
    profileInfoDesc: 'Update your account\'s profile information and email address. ',
    photo: 'Photo',
    selectPhoto: 'Select a new photo',
    removePhoto: 'Remove photo',
    name: 'Name',
    email: 'Email',
    emailNotVerified: 'Your email address is unverified.',
    emailResendVerify: 'Click here to re-send the verification email.',
    emailSendVerify: 'A new verification link has been sent to your email address.',
    emailVerified: 'Your email address has been verified',
    upPassTitle: 'Update Password',
    upPassDesc: 'Ensure your account is using a long, random password to stay secure. After saving you will be automatically logged out',
    currentPass: 'Current Password',
    newPass: 'New Password',
    confirmPass: 'Confirm Password',
    mfaTitle: 'Two Factor Authentication',
    mfaDesc: 'Add additional security to your account using two factor authentication.',
    mfaNotEnabledTitle: 'You have not enabled two factor authentication.',
    mfaNotEnabledDesc: 'When two factor authentication is enabled, you will be prompted for a secure, random token during authentication. You may retrieve this token from your phone\'s Google Authenticator application. ',
    mfaEnabledTitle: 'You have enabled two factor authentication.',
    mfaFinishEnableTitle: 'Finish enabling two factor authentication.',
    mfaConfirmDesc: 'To finish enabling two factor authentication, scan the following QR code using your phone\'s authenticator application or enter the setup key and provide the generated OTP code.',
    mfaEnabledDesc: 'Two factor authentication is now enabled. Scan the following QR code using your phone\'s authenticator application or enter the setup key.',
    mfaRecoveryCode: 'Store these recovery codes in a secure password manager. They can be used to recover access to your account if your two factor authentication device is lost.',
    mfaRegenerateBackup: 'Regenerate Recovery Codes',
    mfaShowRecovery: 'Show Recovery Codes',
    bsTitle: 'Broswer Sessions',
    bsDesc: 'Manage and log out your active sessions on other browsers and devices.',
    bsDescLogout: 'If necessary, you may log out of all of your other browser sessions across all of your devices. Some of your recent sessions are listed below; however, this list may not be exhaustive. If you feel your account has been compromised, you should also update your password.',
    bsThisDevice: 'This Device',
    bsLastActive: 'Last Active',
    bsLogOut: 'Logout all other devices',
    bsLogOutConfirm: 'Please enter your password to confirm you would like to log out of your other browser sessions across all of your devices.',
    notificationTitle: 'Notifications',
    notificationDesc: 'Notifications in your browser allow us to send you messsages',
    notificationExplaination: "During the tournament we can keep you up to date using these notifications. They work on Android, for Apple devices, we need to look how to do this. Make sure that your browser has the permission to display notifications.",
    notificationSubscribe: "Enable Notifications",
};
