export default {
    save: 'Enregistrer',
    saved: 'Enregistré.',
    enable: 'Activer',
    title: 'Profil',
    cancel: 'Abandonner',
    disable: 'Désactiver',
    logout: 'Déconnexion',
    done: 'Effectué.',
    code: 'Code',
    close: 'Fermer',
    confirm: 'Valider',
    profileInfoTitle: 'Informations sur le profil',
    profileInfoDesc: 'Mettez à jour les informations du profil de votre compte et l\'adresse e-mail. ',
    photo: 'Photo',
    selectPhoto: 'Sélectionner une nouvelle photo',
    removePhoto: 'Supprimer la photo',
    name: 'Nom',
    email: 'E-mail',
    emailNotVerified: 'Votre adresse e-mail n\'est pas vérifiée.',
    emailResendVerify: 'Cliquez ici pour renvoyer l\'e-mail de vérification.',
    emailSendVerify: 'Un nouveau lien de vérification a été envoyé à votre adresse e-mail.',
    emailVerified: 'Votre adresse e-mail a été vérifiée',
    upPassTitle: 'Mettre à jour le mot de passe',
    upPassDesc: 'Assurez-vous que votre compte utilise un long mot de passe aléatoire pour rester en sécurité. Après enregistrement, vous serez automatiquement déconnecté',
    currentPass: 'Mot de passe actuel',
    newPass: 'Nouveau mot de passe',
    confirmPass: 'Confirmer le mot de passe',
    mfaTitle: 'Authentification en deux étapes',
    mfaDesc: 'Ajoutez une sécurité supplémentaire à votre compte en utilisant l\'authentification à deux facteurs.',
    mfaNotEnabledTitle: 'Vous n\'avez pas activé l\'authentification à deux facteurs.',
    mfaNotEnabledDesc: 'Lorsque l\'authentification en deux étapes est activée, on vous demandera un jeton sécurisé et aléatoire lors de l\'authentification. Vous pouvez récupérer ce jeton depuis l\'application Google Authenticator de votre téléphone. ',
    mfaEnabledTitle: 'Vous avez activé l\'authentification à deux facteurs.',
    mfaFinishEnableTitle: 'Terminer l\'activation de l\'authentification à deux facteurs.',
    mfaConfirmDesc: 'Pour terminer l\'activation de l\'authentification à deux facteurs, scannez le code QR suivant en utilisant l\'application d\'authentification de votre téléphone ou entrez la clé de configuration et fournissez le code OTP généré.',
    mfaEnabledDesc: 'L\'authentification à deux facteurs est maintenant activée. Scannez le code QR suivant à l\'aide de l\'application d\'authentification de votre téléphone ou entrez la clé d\'installation.',
    mfaRecoveryCode: 'Stockez ces codes de récupération dans un gestionnaire de mots de passe sécurisé. Ils peuvent être utilisés pour récupérer l\'accès à votre compte si votre appareil d\'authentification à deux facteurs est perdu.',
    mfaRegenerateBackup: 'Régénérer les codes de récupération',
    mfaShowRecovery: 'Afficher les codes de récupération',
    bsTitle: 'Sessions du navigateur',
    bsDesc: 'Gérez et déconnectez vos sessions actives sur d\'autres navigateurs et périphériques.',
    bsDescLogout: 'Si nécessaire, vous pouvez vous déconnecter de toutes les autres sessions de votre navigateur sur tous vos appareils. Certaines de vos sessions récentes sont énumérées ci-dessous; cependant, cette liste peut ne pas être exhaustive. Si vous pensez que votre compte a été compromis, vous devriez également mettre à jour votre mot de passe.',
    bsThisDevice: 'Cet appareil',
    bsLastActive: 'Dernier Actif',
    bsLogOut: 'Déconnecter tous les autres appareils',
    bsLogOutConfirm: 'Veuillez entrer votre mot de passe pour confirmer que vous souhaitez vous déconnecter de vos autres sessions de navigateur sur tous vos appareils.',
    notificationTitle: 'Notifications',
    notificationDesc: 'Les notifications dans votre navigateur nous permettent de vous envoyer des messages',
    notificationExplaination: 'Pendant le tournoi, nous pouvons vous tenir à jour en utilisant ces notifications. Ils travaillent sur Android, pour les appareils Apple, nous devons regarder comment faire. Assurez-vous que votre navigateur a la permission d\'afficher les notifications.',
    notificationSubscribe: 'Activer les notifications'
};