export default {
    title: 'Tafels',
    number: 'Tafel nummer',
    color: 'Kleur / Naam',
    color_code: 'Kleurcode',
    titleModalAdd: 'Tafelpaar aanmaken',
    titleModalShow: 'Tafel bekijken',
    titleModalEdit: 'Tafel bewerken',
    pair: 'Tafelpaar'
};
