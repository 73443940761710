export default {
    M00_long_name: 'Inspectie uitrusting',
    M00_1_desc: 'Alle onderdelen en de robot passen in één startgebied en onder de 305 mm:',
    M01_long_name: '3D-CINEMA',
    M01_1_desc: 'De kleine rode balk bevindt zich helemaal rechts van het zwarte frame:',
    M02_long_name: 'THEATERSCÈNE-WISSEL',
    M02_1_desc: 'De rode vlag van het theater staat naar beneden en de kleur van de actieve scène is:',
    M02_2_desc: 'Als de tegenspeler dezelfde kleur heeft, kies dan:',
    M03_long_name: 'MEESLEPENDE ERVARING',
    M03_1_desc: 'De drie meeslepende belevingsschermen staan omhoog:',
    M04_long_name: 'MASTERPIECE SM',
    M04_1_desc: 'Het LEGO®️-kunstwerk van het team bevindt zich ten minste gedeeltelijk in het museum doelgebied:',
    M04_2_desc: 'Het kunstwerk wordt volledig ondersteund door de sokkel:',
    M05_long_name: 'AUGMENTED REALITY STANDBEELD',
    M05_1_desc: 'De oranje hendel van het augmented reality-beeld is volledig naar rechts gedraaid:',
    M06_long_name: 'MUZIEKCONCERT LICHT EN GELUID',
    M06_1_desc: 'De oranje hendel van de lichten is helemaal naar beneden gedraaid:',
    M06_2_desc: 'De oranje hendel van de luidsprekers is helemaal naar links gedraaid:',
    M07_long_name: 'HOLOGRAM-ARTIEST',
    M07_1_desc: 'De oranje drukactivator van de hologram-artiest is volledig voorbij de zwarte podiumscenelijn:',
    M08_long_name: 'ROLLENDE CAMERA',
    M08_1_desc: 'De witte aanwijzer van de rollende camera is:',
    M09_long_name: 'FILMSET',
    M09_1_desc: 'De boot raakt de mat en is volledig voorbij de zwarte scènelijn:',
    M09_2_desc: 'De camera raakt de mat en is ten minste gedeeltelijk in het camera doelgebied:',
    M10_long_name: 'MENGPANEEL',
    M10_1_desc: 'Het aantal schuifregelaars die omhoog staan:',
    M11_long_name: 'LICHTSHOW',
    M11_1_desc: 'De witte wijzer van de lichtshow bevindt zich binnen de zone:',
    M12_long_name: 'VIRTUAL REALITY KUNSTENAAR',
    M12_1_desc: 'De kip intact is en van haar startpositie verplaatst:',
    M12_2_desc: 'De kip is over of helemaal voorbij de lavendel stip:',
    M13_long_name: 'KNUTSELWERKMAKER',
    M13_1_desc: 'Het oranje-witte deksel van de knutselwerkmaker is helemaal open:',
    M13_2_desc: 'De lichtroze sluiting van de knutselwerkmaker wijst recht naar beneden:',
    M14_long_name: 'PUBLIEK VERVOEREN',
    M14_1_desc: 'Aantal toeschouwers die zich volledig op een doelgebied bevinden:',
    M14_2_desc: 'Aantal doelgebieden met ten minste één toeschouwer volledig in:',
    M15_long_name: 'EXPERTS VERVOEREN',
    M15_1_desc: 'Aantal experts die zich ten minste gedeeltelijk op hun doelgebied bevinden:'
};