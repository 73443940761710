export default {
    title: "Users",
    basicInfo: "Basic Information",
    roles: "Roles",
    assignedTeams: "Assigned Teams",
    passwordReset: "Password Reset",
    name: "Name",
    email: "Email",
    locale: "Language",
    current_team_id: "Teamlist",
    password: "Password",
    passwordConfirm: "Confirm Password",
    titleModalAdd: "Create User",
};
