export default {
    title: 'Teams',
    titleTeamList: "Deze teams doen mee:",
    number: 'Nummer',
    name: 'Naam',
    numberLong: 'Teamnummer',
    nameLong: 'Teamnaam',
    id: 'ID',
    affiliate: 'Organisatie',
    actions: 'Acties',
    titleModalAdd: 'Team toevoegen',
    creationSuccess: 'Team is succesvol aangemaakt!',
    adv: {
        grouppicture: 'Groepsfoto',
        basicinfo: 'Basis Informatie',
        moreinfo: 'Meer Informatie',
        weblinks: 'Weblinks',
        yell: 'Yell',
        robot_desc: 'Robot omschrijving',
        reseach_desc: 'Innovatie project beschrijving',
        website: 'Website',
        instagram: 'Instagram',
        facebook: 'Facebook',
        twitter: 'X',
        snapchat: 'Snapchat'
    },
    public: {
        teamInfo: "Meer over dit team",
        warningPracticeScore: 'Waarschuwing: Deze score is een oefenronde, deze scores tellen niet mee voor het ranking',
        viewResult: 'Bekijk scoreformulier',
        yell: 'Onze yell',
        aboutRobot: 'Over de robot',
        aboutResearch: 'Over ons project',
        whatsThePlan: 'Wat is het plan van vandaag?',
        dayschedule: 'Het schema voor',
        matchResults: 'Wedstrijdresultaten',
        scoreHidden: 'Score verborgen'
    }
};
