export default {
    title: 'Manches',
    name: 'Nom de la ronde',
    is_practice: 'Tour d\'Entraînement',
    score_hidden: 'Cacher les scores',
    titleModalAdd: 'Créer une manche',
    titleModalShow: 'Voir la manche',
    titleModalEdit: 'Modifier la manche',
    game_round: 'Manche de jeu',
    score_visible: 'Visible',
    type: 'Type',
    visibility: 'Visibilité des scores'
};