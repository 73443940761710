export default {
    M00_long_name: 'Uitrustingsinspectie',
    M00_1_desc: 'Alle onderdelen en de robot passen in één lanceringsgebied en onder de 305 mm (12 in.):',
    M01_long_name: '3D-Bioscoop',
    M01_1_desc: 'De kleine rode valk bevindt zich helemaal rechts van het zwarte frame:',
    M02_long_name: 'Theaterscenewisseling',
    M02_1_desc: 'De rode vlag van het theater naar beneden is, en de kleur van de scene is:',
    M02_2_desc: 'De kleur van de andere kant is:',
    M03_long_name: 'De complete ervaring',
    M03_1_desc: 'Als de drie immersive experience schermen omhoog staan:',
    M04_long_name: 'Masterpiece SM',
    M04_1_desc: 'Het LEGO®️ kunstwerk van je team zit ten minste gedeeltelijk in het doelgebied van het museum:',
    M04_2_desc: 'Het kunstwerk word volledig ondersteund door het voetstuk:',
    M05_long_name: 'Augmented reality standbeeld',
    M05_1_desc: 'De oranje hendel van de augmented reality is volledig naar rechts gedraaid:',
    M06_long_name: 'Muziekconcert - Licht en geluid',
    M06_1_desc: 'De oranje hendel van het licht is volledig naar beneden gedraaid:',
    M06_2_desc: 'De oranje hendel van de spreker is volledig naar links gedraaid:',
    M07_long_name: 'Hologram artiest',
    M07_1_desc: 'De oranje knop van de hologram artiest is volledig voorbij de lijn in het zwarte toneel:',
    M08_long_name: 'Rollende camera',
    M08_1_desc: 'De rollende camera is de witte wijzer is voorbij:',
    M09_long_name: 'Filmset',
    M09_1_desc: 'De boot raakt de mat en is volledig voorbij de zwarte scène lijn:',
    M09_2_desc: 'De camera raakt de mat en bevindt zich ten minste gedeeltelijk in het camera doelgebied:',
    M10_long_name: 'Geluidmixer',
    M10_1_desc: 'Aantal geluidmixers regelaars die zijn verhoogd:',
    M11_long_name: 'Lichtshow',
    M11_1_desc: 'De witte wijzer van de lichtshow is binnen zone:',
    M12_long_name: 'Virtual reality artiest',
    M12_1_desc: 'De kip is nog heel en is van zijn beginpositie afgeweken:',
    M12_2_desc: 'De kip is boven of voorbij het paarse punt:',
    M13_long_name: 'Knutselkunstmaker',
    M13_1_desc: 'Het oranje-witte deksel van de knutselmachine is helemaal open:',
    M13_2_desc: 'De lichtroze grendel van de knutselmachine wijst recht naar beneden:',
    M14_long_name: 'Publiek plaatsen',
    M14_1_desc: 'Hoeveel publiek bevindt zich volledig in een publieksdoelgebied:',
    M14_2_desc: 'Aantal publieksdoelgebieden met ten minste één iemand van het publiek er volledig in:',
    M15_long_name: 'Experts plaatsen',
    M15_1_desc: 'Aantal experts ten minste gedeeltelijk op hun doelbestemmingen:'
};