export default {
    title: "Tables",
    number: "Table number",
    color: "Color",
    color_code: "Colorcode",
    titleModalAdd: "Create table pair",
    titleModalShow: "View table",
    titleModalEdit: "Edit table",
    pair: "Tablepair"
};
