export default {
    save: 'Opslaan',
    saved: 'Opgeslagen.',
    enable: 'Inschakelen',
    title: 'Profiel',
    cancel: 'Annuleren',
    disable: 'Uitschakelen',
    logout: 'Uitloggen',
    done: 'Klaar.',
    code: 'Code',
    close: 'Sluiten',
    confirm: 'Bevestigen',
    profileInfoTitle: 'Profiel Informatie',
    profileInfoDesc: 'Werk de profielinformatie en het e-mailadres van uw account bij.',
    photo: 'Foto',
    selectPhoto: 'Upload een nieuwe foto',
    removePhoto: 'Verwijder foto',
    name: 'Naam',
    email: 'Email',
    emailNotVerified: 'Je email adres is niet geverifieerd',
    emailResendVerify: 'Klik hier om je email aders te verifieren...',
    emailSendVerify: 'Een nieuwe verificatie link is verstuurd naar je email adres.',
    emailVerified: 'Je email adres is geverifieerd',
    upPassTitle: 'Verander wachtwoord',
    upPassDesc: 'Gebruik een lang, random wachtwoord om je account veilig te houden. Na het veranderen zal u automatisch worden uitgelogd',
    currentPass: 'Huidig wachtwoord',
    newPass: 'Nieuw wachtwoord',
    confirmPass: 'Bevestig Wachtwoord',
    mfaTitle: 'Tweefactorauthenticatie',
    mfaDesc: 'Voeg extra beveiliging toe aan uw account met behulp van tweefactorauthenticatie.',
    mfaNotEnabledTitle: 'U hebt geen tweefactorauthenticatie ingeschakeld.',
    mfaNotEnabledDesc: 'Wanneer verificatie met twee factoren is ingeschakeld, wordt u tijdens het inloggen gevraagd om een veilig, willekeurig token. U kunt dit token zien in bijvoorbeeld de Google Authenticator-applicatie op uw telefoon. ',
    mfaEnabledTitle: 'Je hebt tweefactorauthenticatie ingeschakeld.',
    mfaFinishEnableTitle: 'Maak het inschakelen van twee-factor-authenticatie af.',
    mfaConfirmDesc: 'Om de tweefactorauthenticatie te voltooien, scant u de volgende QR-code met de authenticatietoepassing van uw telefoon of voert u de installatiesleutel in en geeft u de gegenereerde OTP-code op.',
    mfaEnabledDesc: 'Tweefactorauthenticatie is nu ingeschakeld. Scan de volgende QR-code met de authenticatietoepassing van uw telefoon of voer de instelsleutel in.',
    mfaRecoveryCode: 'Bewaar deze herstelcodes in een veilige wachtwoordmanager. Ze kunnen worden gebruikt om de toegang tot uw account te herstellen als uw tweefactorauthenticatieapparaat verloren gaat.',
    mfaRegenerateBackup: 'Herstelcodes genereren',
    mfaShowRecovery: 'Laat herstelcodes zien',
    bsTitle: 'Broswer sessies',
    bsDesc: 'Beheer en log uit uw actieve sessies op andere browsers en apparaten.',
    bsDescLogout: 'Indien nodig kunt u uitloggen uit al uw andere browsersessies op al uw apparaten. Enkele van uw recente sessies staan hieronder vermeld; deze lijst is echter mogelijk niet volledig. Als u denkt dat uw account gecompromitteerd is, moet u ook uw wachtwoord bijwerken.',
    bsThisDevice: 'Dit apparaat',
    bsLastActive: 'Laatst actief',
    bsLogOut: 'Uitloggen op andere apparaten',
    bsLogOutConfirm: 'Voer uw wachtwoord in om te bevestigen dat u zich wilt afmelden van uw andere browsersessies op al uw apparaten.',
    notificationTitle: 'Notifications',
    notificationDesc: 'Notifications in your browser allow us to send you messsages',
    notificationExplaination: 'During the tournament we can keep you up to date using these notifications. They work on Android, for Apple devices, we need to look how to do this. Make sure that your browser has the permission to display notifications.',
    notificationSubscribe: 'Enable Notifications'
};