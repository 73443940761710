export default {
    title: "Remarks",
    madeBy: "Made by",
    category: "Category",
    remark: "Remark",
    aboutTeam: "Remark about:",
    value: "The remark",
    coach: "Coach",
    jury: "Jury",
    table: "Table",
    team: "Team",
    parents: "Parents",
    others: "Others",
};
