export default {
    title: "Teams",
    titleTeamList: "Teams",
    number: "Number",
    name: "Name",
    numberLong: "Teamnumber",
    nameLong: "Teamname",
    id: "ID",
    affiliate: "Organisation",
    actions: "Actions",
    titleModalAdd: "Create team",
    creationSuccess: "Team has been created successfully!",
    adv: {
        grouppicture: "Grouppicture",
        basicinfo: "Basic information",
        moreinfo: "More Information",
        weblinks: "Weblinks",
        yell: "Yell",
        robot_desc: "Robot Description",
        reseach_desc: "Innovation project description",
        website: "Website",
        instagram: "Instagram",
        facebook: "Facebook",
        twitter: "X",
        snapchat: "Snapchat",

    },
    public: {
        teamInfo: "More about the team",
        warningPracticeScore: "Warning: This score is a practice round, these scores won't count towards the final result",
        viewResult: "View scoreform",
        yell: "Our yell",
        aboutRobot: "About the robot",
        aboutResearch: "About our research",
        whatsThePlan: "What is the plan for today?",
        dayschedule: "The schedule for",
        matchResults: "Match results",
        scoreHidden: "Score hidden",
    }
};
