export default {
    title: 'Rondes',
    name: 'Ronde naam',
    is_practice: 'Oefenronde',
    score_hidden: 'Verborgen Score',
    titleModalAdd: 'Ronde aanmaken',
    titleModalShow: 'Ronde bekijken',
    titleModalEdit: 'Ronde aanpassen',
    game_round: 'Wedstrijdronde',
    score_visible: 'Zichtbaar',
    type: 'Type',
    visibility: 'Score Zichtbaar'
};