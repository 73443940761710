export default {
    title: 'Entrée du score',
    yes: 'Oui',
    no: 'Non',
    blue: 'Bleu',
    pink: 'Rose',
    orange: 'Orange',
    lightBlue: 'Bleu clair',
    darkBlue: 'Bleu foncé',
    yellow: 'Jaune',
    green: 'Vert',
    gp_long_name: 'JETONS DE PRÉCISION',
    gp_desc: 'Si le nombre de jetons de précision restants est :',
    description: 'Description',
    updates: 'Mises à jour',
    noChange: 'Pas de changement'
};