export default {
    M00_long_name: 'INSPECTION D’ÉQUIPEMENT',
    M00_1_desc: 'Tout l\'équipement de l\'équipe tient dans une seule zone de lancement et ne dépasse pas 305 mm :',
    M01_long_name: 'CINÉMA 3D',
    M01_1_desc: 'La petite brique rouge du cinéma 3D est complètement à droite du cadre noir :',
    M02_long_name: 'CHANGEMENT DE DÉCOR',
    M02_1_desc: 'Votre drapeau rouge de théâtre est baissé et que la couleur du décor actif est :',
    M02_2_desc: 'Si l\'autre côté est de la même couleur, sélectionnez-le :',
    M03_long_name: 'EXPÉRIENCE IMMERSIVE',
    M03_1_desc: 'Les trois écrans d’expérience immersive sont levés :',
    M04_long_name: 'MASTERPIECE SM',
    M04_1_desc: 'Si votre oeuvre d’art LEGO est au moins partiellement dans la zone ciblée du musée :',
    M04_2_desc: 'L’oeuvre d’art est complètement supportée par le socle :',
    M05_long_name: 'STATUE EN RÉALITÉ AUGMENTÉE',
    M05_1_desc: 'Le levier orange de la statue en réalité augmentée est complètement tourné vers la droite :',
    M06_long_name: 'CONCERT SON ET LUMIÈRE',
    M06_1_desc: 'Le levier orange de l’éclairage est complètement tourné vers le bas :',
    M06_2_desc: 'Le levier orange des haut-parleurs est complètement tourné vers la gauche :',
    M07_long_name: 'ARTISTE EN HOLOGRAMME',
    M07_1_desc: 'L’activateur poussoir orange a complètement dépassé la ligne repère noire de la scène :',
    M08_long_name: 'CAMÉRA MOBILE SUR RAIL',
    M08_1_desc: 'Le pointeur blanc de la caméra mobile est :',
    M09_long_name: 'PLATEAU DE TOURNAGE',
    M09_1_desc: 'Le bateau touche le tapis et dépasse complètement la ligne repère de la scène :',
    M09_2_desc: 'La caméra touche le tapis et est en partie dans la zone ciblée de la caméra :',
    M10_long_name: 'TABLE DE MIXAGE',
    M10_1_desc: 'Nombre de curseurs de la table de mixage qui sont relevés :',
    M11_long_name: 'JEU DE LUMIÈRE',
    M11_1_desc: 'Le pointeur blanc du jeu de lumière est dans la zone:',
    M12_long_name: 'ARTISTE EN RÉALITÉ VIRTUELLE',
    M12_1_desc: 'Le poulet est intact et a été déplacé de sa position initiale :',
    M12_2_desc: 'Il se trouve au-dessus ou complètement dépassé le point lavandet:',
    M13_long_name: 'CRÉATION ARTISANALE',
    M13_1_desc: 'Le couvercle orange et blanc de la machine d’artisanat est complètement ouvert :',
    M13_2_desc: 'Le verrou rose pâle de la machine d’artisanat pointe directement vers le bas :',
    M14_long_name: 'TRANSPORT DU PUBLIC',
    M14_1_desc: 'Nombre de membres du public se trouvant complétement dans une destination cible :',
    M14_2_desc: 'Nombre de destinations cibles dont au moins un membre du public s\'y trouve complètement :',
    M15_long_name: 'TRANSPORT DES EXPERTS',
    M15_1_desc: 'Nombre d\'experts connaissant au moins partiellement leurs destinations cibles :'
};