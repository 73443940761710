import dataFooterMsg from "./dataFooter.js";

export default {
    noDataText: "No records.",
    dataFooter: dataFooterMsg,
    input: {
        clear: "Clear",
    },
    open: "Open",
};
